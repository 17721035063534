/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

/* Global Styles */
body {
  font-family: 'Anton', sans-serif;
  text-transform: uppercase;
}
.video-js {
  width: 100% !important;
  height: 400px !important;
  background-color: black;
}

.affiliate-logo{
  width: 190px;
}
/* Embla Carousel Styles */
.embla {
  position: relative;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.embla__slide {
  flex: 0 0 100%; /* Default for small screens: 1 player per slide */
}

@media (min-width: 768px) {
  .embla__slide {
    flex: 0 0 33.33%; /* Medium screens: 3 players per slide */
  }
}

@media (min-width: 1200px) {
  .embla__slide {
    flex: 0 0 20%; /* Large screens: 5 players per slide */
  }
}

.embla__prev, .embla__next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: red; /* Red background */
  color: white; /* White arrow */
  border: none;
  cursor: pointer;
  border-radius: 50%; /* Makes the button round */
  width: 40px; /* Adjust button size */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; /* Adjust the size of the arrow */
}

.embla__prev {
  left: 10px;
}

.embla__next {
  right: 10px;
}

.embla__prev:hover, .embla__next:hover {
  background-color: darkred; /* Darker red on hover */
}

/* Player Card Styles */
.player-card {
  color: white;
  border-radius: 10px;
  overflow: hidden;
  background-color: #B80808;
  border: black;
}

.card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Navigation and Hero Section */
.navbar {
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.logo {
  width: 135px;
}

.banner-image {  
  background: url('/public/images/banner.jpg') no-repeat center center;
  background-size: cover;
  background-position-y: top;
  height: 600px;
  width: 100%;
}

.bg-red {
  background-color: #500004;
}

/* Hero Section Titles */
.hero-title {
  font-size: 4rem;
}

.hero-subtitle {
  font-size: 1.5rem;
}

/* Button Styles */
.btn-primary {
  background-color: #B80808; /* Red color for the button */
  border: none;
}

.btn-primary:hover {
  background-color: #cc0000; /* Darker red on hover */
}

/* Hovering Phone Button */
.hovering-phone-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #cc0000;
  color: white;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
  text-decoration: none;
  z-index: 1000;
}

.hovering-phone-button:hover,
.hovering-phone-button:active {
  background-color: #B80808;
  transform: scale(1.1);
}

/* Section Styles */
.section-title {
  font-size: 4rem;
  margin-bottom: 20px;
}

.section-text {
  font-size: 1.1rem;
  color: #495057;
  line-height: 1.6;
}

/* Coach Image */
.coach-image {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Contact Us Section */
.register-container {
  background-color: #B80808;
}

.form-label {
  font-size: 1rem;
}

.form-control {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

/* Footer Styles */
.footer {
  padding: 40px 0;
  background-color: #343a40;
  color: #ffffff;
}

.footer h5 {
  font-size: 2.75rem;
  margin-bottom: 20px;
}

.footer ul {
  padding-left: 0;
  list-style: none;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  color: #ffffff;
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: underline;
}

.footer .social-media-icons a {
  color: #ffffff;
  margin: 0 10px;
  font-size: 1.5rem;
}

.footer .social-media-icons a:hover {
  color: #cccccc;
}
.next-match {
  border-radius: 8px;
  padding: 30px;
  color: #fff;
  background-position: center;
  background-size: cover;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.next-match .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.next-match h4 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  z-index: 2;
}

.next-match p {
  font-size: 1.25rem;
  z-index: 2;
}

.next-match .btn {
  z-index: 2;
}
